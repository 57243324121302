import  React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";


const Dashboard = () => {


  const navigate=useNavigate();
    const Stumobile = localStorage.getItem("mobile");
 
    const [stu,setstu]= useState('');
    const [count,setcount]= useState('');

    const url = 'http://localhost:5000/api/teacher?Ph='+Stumobile;   
     

    useEffect(() => {   
      if(Stumobile)    
         showContent(url);  
        else
        {
          navigate("/Login");
        }        
    }, []); 

    const showContent = async (url) => {

        const res = await fetch(url);
        const data = await res.json();       
        setstu(data.Obj_data);

        
       
        localStorage.setItem("Class", data.Obj_data[0].ClassTeacher);  
        

        const urlCount = 'http://localhost:5000/api/student/count?Class='+ data.Obj_data[0].ClassTeacher; 

        const resount = await fetch(urlCount);
        const datacount = await resount.json();       
        setcount (datacount.count)
        console.log(datacount);
        
      }
    
        return (
            <main id="main" class="main">

                <div class="pagetitle">
                 
                    <h1>Dashboard</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>

                <section class="section dashboard">
                < div className="row col-lg-12 col-md-12 ">
          <div className="row col-lg-12 col-md-12 ">


            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Teacher's Data</h5>


                <table className="table table-hover">
                  <thead>
                    <tr>
                    <th scope="col">Name</th>
              <th scope="col">Email</th>            
              <th scope="col">Mobile</th>              
              <th scope="col">Subject</th>
              <th scope="col">Class Teacher</th> 
               <th scope="col">Address</th>
              <th scope="col">Joindate</th>
             
                    
                     
                    </tr>
                  </thead>
                  <tbody>
                     {

                      stu && stu.map((stu, i) => (
                        <tr key={stu._id}>
                     
                     <td>{stu.Name} </td>
                  <td>{stu.email}</td>                 
                  <td>{stu.Ph}</td>                  
                  <td>{stu.Subject} </td>
                  <td>{stu.ClassTeacher}</td> 
                  <td>{stu.Address} </td>
                  <td>{stu.Joindate.split("T")[0]} </td>
               
                         
                        </tr>
                      ))}
 
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          </div>


          {/* Fee Data--------------------*/}


          < div className="row col-lg-6 col-md-8 ">
        

          <div className="card">
              <div className="card-body">
                <h5 className="card-title">Total Student</h5>
                <hr></hr>
                {
                   stu[0] && <>
                        <h6>Class:  {stu[0].ClassTeacher}</h6>
                        <h6>Total Student:  {count}</h6>
                        </>
                }

               
              </div>
            </div>
            </div>
            

                </section>
            </main>
        );
    }


export default Dashboard;