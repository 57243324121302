import React from 'react';

const Sidebar =()=>{
    return(
        <aside id="sidebar" className="sidebar">

        <ul className="sidebar-nav" id="sidebar-nav">
    
          <li className="nav-item">
            <a className="nav-link " href="/">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>    
         
    
    
          {/* <li className="nav-heading">Pages</li> */}
    
          
       
         
    
          <li className="nav-item">
            <a className="nav-link " href="/Register">
              <i className="bi bi-grid"></i>
              <span>Student Register</span>
            </a>
          </li> 
    
        

          <li className="nav-item">
            <a className="nav-link " href="/StudentPage">
              <i className="bi bi-box-arrow-in-right"></i>
              <span>Student Record</span>
            </a>
          </li>

         
    
          
    
        </ul>
    
      </aside>

    );
}
export default Sidebar;