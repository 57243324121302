import './App.css';
import Header from './component/Header';
import Sidebar from './component/Sidebar';
import Footer from './component/Footer';
import Login from './component/Login';
import Register from './component/Register';
import Dashboard from './component/Dashboard';
import StudentPage from './component/StudentPage';
import UpdateStudent from './component/UpdateStudent';
import { Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div>
      <Header />
      <Sidebar />
      <Routes>
        <Route path='Login' element={<Login />}></Route>
        <Route path='Register' element={<Register />}></Route>       
        <Route path='/' element={<Dashboard />}></Route>       
        <Route path ="StudentPage" element={<StudentPage/>}></Route>       
        <Route path ="UpdateStudent/:id" element={<UpdateStudent/>}></Route>     
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
