import React from 'react';
import { useNavigate } from "react-router-dom";

const  Header =()=> {


  const navigate = useNavigate();

  const handelSubmit=(e)=>{
    e.preventDefault();
    localStorage.clear();
    navigate("Login");


  }
    
        return (  
        <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between">
          <a href="index.html" className="logo d-flex align-items-center">
            <img src="assets/img/logo.png" alt=""/>
            <span className="d-none d-lg-block">School Admin</span>
          </a>
          <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>
    
        <div className="search-bar">
          <form className="search-form d-flex align-items-center" method="POST" action="#">
            <input type="text" name="query" placeholder="Search" title="Enter search keyword"/>
            <button type="submit" title="Search"><i className="bi bi-search"></i></button>
           
          </form>
        </div>   
     
        <div className="container-fluid">
      <div className="row justify-content-end">

      <div className="col-2" style={{ marginRight: "28px"}} >
        <button className="btn btn-primary w-100"  style={{minWidth: "100px"}}  onClick={handelSubmit}  type="submit">Log Out</button>
      
      </div>
      </div></div>
    
      </header>);
}

export default Header;