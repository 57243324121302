// ProtectedRoute.js

import React from 'react';
import { useNavigate } from "react-router-dom";

const ProtectedRoute =()=>
{ 
 
  const navigate=useNavigate();
    const loggedInUser = localStorage.getItem("authenticated");
    if(!loggedInUser)
    {      
        navigate("/Login");
    }  
}
export default ProtectedRoute;
